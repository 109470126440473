var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BNavbar'),_c('div',{staticClass:"pt-5 mb-md-5 bg-contact"},[_c('b-container',[_c('b-row',{staticClass:"py-5"},[_c('b-col',{staticClass:"mx-auto text-center",attrs:{"sm":"12","md":"9"}},[(_vm.visibleTitle)?_c('div',[_c('h1',{staticClass:"text-primary font-weight-light display-4"},[_c('typewriter',{staticClass:"typewriter",attrs:{"type-interval":30}},[_vm._v(" Contáctate con nosotros ")])],1)]):_vm._e()])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.visibleForm)?_c('b-row',{staticClass:"contact-form"},[_c('b-col',{staticClass:"contact-left pb-5",attrs:{"sm":"12","md":"7"}},[_c('h3',{staticClass:"font-weight-light text-primary mt-2 mt-lg-0 p-md-5"},[_vm._v(" Para contactarte escríbenos a "),_c('a',{attrs:{"href":"mailto:contacto@gruposable.lat"}},[_vm._v("contacto@gruposable.lat")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-5",attrs:{"id":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-muted text-uppercase small ls-sable"},[_vm._v(" Información Personal ")])]),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12","md":"12","lg":"6"}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only small ls-sable text-uppercase",attrs:{"for":"inline-form-input-name s"}},[_vm._v("Nombre")]),_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"Ingresa tu Nombre"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',[_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12","md":"12","lg":"6"}},[_c('div',{staticClass:"form_group"},[_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only small ls-sable text-uppercase",attrs:{"for":"inline-form-input-name"}},[_vm._v("Apellido")]),_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"Ingresa tu Apellido"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('small',[_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12","md":"12","lg":"6"}},[_c('div',{staticClass:"form_group"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only small ls-sable text-uppercase",attrs:{"for":"inline-form-input-name"}},[_vm._v("Correo Electrónico")]),_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"Ingresa tu Correo"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',[_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12","md":"12","lg":"6"}},[_c('div',{staticClass:"form_group"},[_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only small ls-sable text-uppercase",attrs:{"for":"inline-form-input-name"}},[_vm._v("Teléfono")]),_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"Ingresa tu Teléfono"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',[_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-muted text-uppercase small ls-sable"},[_vm._v(" Información de la Compañia ")])]),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12","md":"12","lg":"6"}},[_c('div',{staticClass:"form_group"},[_c('ValidationProvider',{attrs:{"name":"company","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only small ls-sable text-uppercase",attrs:{"for":"inline-form-input-name s"}},[_vm._v("Empresa")]),_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"Ingresa tu Empresa"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}}),_c('small',[_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('b-col',{staticClass:"mb-4",attrs:{"sm":"12","md":"12","lg":"6"}},[_c('div',{staticClass:"form_group"},[_c('ValidationProvider',{attrs:{"name":"country","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only small ls-sable text-uppercase",attrs:{"for":"inline-form-input-name"}},[_vm._v("País")]),_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"id":"inline-form-input-name","placeholder":"Ingresa tu País"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',[_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form_group"},[_c('ValidationProvider',{attrs:{"name":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only small ls-sable text-uppercase",attrs:{"for":"inline-form-input-name"}},[_vm._v("Mensaje")]),_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Dejanos tu mensaje aquí...","rows":"6","max-rows":"6"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('small',[_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-button',{staticClass:"mt-4 text-white px-5",attrs:{"variant":"primary","size":"md","type":"submit"}},[_vm._v("Enviar")])],1)],1)],1)]}}],null,false,3861263599)})],1),_c('b-col',{staticClass:"contact-right p-0",attrs:{"sm":"12","md":"5"}},[_c('div',{staticClass:"h3 font-weight-light text-white p-5"},[_vm._v(" la tecnología digital como un instrumento al servicio de las personas ")]),_c('b-col',{staticClass:"border-red position-absolute bottom-0",attrs:{"md":"6"}})],1)],1):_vm._e()],1)],1)],1),_c('Footer',{staticClass:"mt-sm-5 mt-xl-0"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }