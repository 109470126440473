<template>
  <div>
    <BNavbar></BNavbar>
    <div class="pt-5 mb-md-5 bg-contact">
      <b-container>
        <b-row class="py-5">
          <b-col sm="12" md="9" class="mx-auto text-center">
            <div v-if="visibleTitle">
              <h1 class="text-primary font-weight-light display-4">
                <typewriter class="typewriter" :type-interval="30">
                  Contáctate con nosotros
                </typewriter>
              </h1>
            </div>
          </b-col>
        </b-row>
        <transition name="fade">
          <b-row v-if="visibleForm" class="contact-form">
            <b-col sm="12" md="7" class="contact-left pb-5">
              <h3 class="font-weight-light text-primary mt-2 mt-lg-0 p-md-5">
                Para contactarte escríbenos a
                <a href="mailto:contacto@gruposable.lat"
                  >contacto@gruposable.lat</a
                >
              </h3>

              <!-- <img
                src="/assets/geography.png"
                width="325"
                height="436"
                alt="Geografia Grupo Sable"
                class="float-end"
                style="float: right"
              /> -->
              <ValidationObserver v-slot="{ handleSubmit }">
                <b-form
                  id="form"
                  @submit.prevent="handleSubmit(onSubmit)"
                  class="p-5"
                >
                  <b-row>
                    <b-col cols="12">
                      <div class="text-muted text-uppercase small ls-sable">
                        Información Personal
                      </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="6" class="mb-4">
                      <div class="form-group">
                        <ValidationProvider
                          name="name"
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <label
                            class="sr-only small ls-sable text-uppercase"
                            for="inline-form-input-name s"
                            >Nombre</label
                          >
                          <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="Ingresa tu Nombre"
                            v-model="form.name"
                          ></b-form-input>
                          <small>
                            <p class="error">{{ errors[0] }}</p>
                          </small>
                        </ValidationProvider>
                      </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="6" class="mb-4">
                      <div class="form_group">
                        <ValidationProvider
                          name="lastName"
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <label
                            class="sr-only small ls-sable text-uppercase"
                            for="inline-form-input-name"
                            >Apellido</label
                          >
                          <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="Ingresa tu Apellido"
                            v-model="form.lastname"
                          ></b-form-input>
                          <small>
                            <p class="error">{{ errors[0] }}</p>
                          </small>
                        </ValidationProvider>
                      </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="6" class="mb-4">
                      <div class="form_group">
                        <ValidationProvider
                          name="email"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <label
                            class="sr-only small ls-sable text-uppercase"
                            for="inline-form-input-name"
                            >Correo Electrónico</label
                          >
                          <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="Ingresa tu Correo"
                            v-model="form.email"
                          ></b-form-input>
                          <small>
                            <p class="error">{{ errors[0] }}</p>
                          </small>
                        </ValidationProvider>
                      </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="6" class="mb-4">
                      <div class="form_group">
                        <ValidationProvider
                          name="phone"
                          rules="required|numeric"
                          v-slot="{ errors }"
                        >
                          <label
                            class="sr-only small ls-sable text-uppercase"
                            for="inline-form-input-name"
                            >Teléfono</label
                          >
                          <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="Ingresa tu Teléfono"
                            v-model="form.phone"
                          ></b-form-input>
                          <small>
                            <p class="error">{{ errors[0] }}</p>
                          </small>
                        </ValidationProvider>
                      </div>
                    </b-col>

                    <b-col cols="12" class="mt-4">
                      <div class="text-muted text-uppercase small ls-sable">
                        Información de la Compañia
                      </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="6" class="mb-4">
                      <div class="form_group">
                        <ValidationProvider
                          name="company"
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <label
                            class="sr-only small ls-sable text-uppercase"
                            for="inline-form-input-name s"
                            >Empresa</label
                          >
                          <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="Ingresa tu Empresa"
                            v-model="form.company"
                          ></b-form-input>
                          <small>
                            <p class="error">{{ errors[0] }}</p>
                          </small>
                        </ValidationProvider>
                      </div>
                    </b-col>

                    <b-col sm="12" md="12" lg="6" class="mb-4">
                      <div class="form_group">
                        <ValidationProvider
                          name="country"
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <label
                            class="sr-only small ls-sable text-uppercase"
                            for="inline-form-input-name"
                            >País</label
                          >
                          <b-form-input
                            id="inline-form-input-name"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            placeholder="Ingresa tu País"
                            v-model="form.country"
                          ></b-form-input>
                          <small>
                            <p class="error">{{ errors[0] }}</p>
                          </small>
                        </ValidationProvider>
                      </div>
                    </b-col>

                    <b-col sm="12">
                      <div class="form_group">
                        <ValidationProvider
                          name=""
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label
                            class="sr-only small ls-sable text-uppercase"
                            for="inline-form-input-name"
                            >Mensaje</label
                          >
                          <b-form-textarea
                            id="textarea"
                            v-model="form.message"
                            placeholder="Dejanos tu mensaje aquí..."
                            rows="6"
                            max-rows="6"
                          ></b-form-textarea>
                          <small>
                            <p class="error">{{ errors[0] }}</p>
                          </small>
                        </ValidationProvider>
                      </div>
                    </b-col>
                    <b-col sm="12">
                      <b-button
                        variant="primary"
                        size="md"
                        class="mt-4 text-white px-5"
                        type="submit"
                        >Enviar</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </b-col>
            <b-col sm="12" md="5" class="contact-right p-0">
              <div class="h3 font-weight-light text-white p-5">
                la tecnología digital como un instrumento al servicio de las
                personas
              </div>
              <b-col
                md="6"
                class="border-red position-absolute bottom-0"
              ></b-col>
            </b-col>
          </b-row>
        </transition>
      </b-container>
    </div>
    <Footer class="mt-sm-5 mt-xl-0"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BNavbar from "@/components/BNavbar.vue";
import Footer from "@/components/Footer.vue";
import Typewriter from "typewriter-vue";
import axios from "axios";

export default {
  name: "Contacto",
  components: {
    BNavbar,
    Footer,
    Typewriter,
  },
  data() {
    return {
      scrollPosition: null,
      visibleTitle: false,
      visibleForm: false,
      form: {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
        country: "",
        message: "",
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    setTimeout(() => (this.visibleTitle = true), 1000);
    setTimeout(() => (this.visibleForm = true), 2000);
  },
  methods: {
    onSubmit() {
      this.sendForm();
    },
    sendForm: function () {
      // TODO: Move URL to .env variable
      axios
        .post(
          "https://us-central1-sable-website-73ca2.cloudfunctions.net/mail/send",
          this.form
        )
        .then(() => {
          this.$bvModal
            .msgBoxOk(
              "Gracias por escribirnos. Pronto nos contactaremos contigo."
            )
            .then(() => {
              document.getElementById("form").reset();
            });
        })
        .catch((error) => {
          alert("Error!" + error);
        });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: "Contacto | Grupo Sable",
      meta: [
        {
          name: "description",
          content:
            "Contáctate con nosotros llenando el formulario y agendaremos una reunión a la brevedad",
        },
      ],
      link: [{ rel: "canonical", href: "https://sable.lat/contacto" }],
    };
  },
};
</script>
<style scoped>
.error {
  color: #f44336;
}
</style>
